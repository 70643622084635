import Index from "pages";
import React, { Component } from 'react';
import {
  Route,
  Switch,
  Redirect,
  withRouter,
  BrowserRouter as Router,
} from "react-router-dom";
import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
  }
`;

class App extends Component {
  render() {
    const { history } = this.props

    return (
      <Router>
        <GlobalStyles />
        <Switch>
          <Route history={history}
                 path="/en/makeup"
                 component={Index} />
          <Route history={history}
                 path="/ru/makeup"
                 component={Index} />
          <Redirect from='/'
                    to='/en/makeup'/>
        </Switch>
      </Router>
    );
  }
}

export default withRouter(App);
