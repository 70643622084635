import i18n from "i18next";
import {initReactI18next} from "react-i18next";

i18n
    .use(initReactI18next)
    .init({
        resources: {
            ru: {
                translation: {
                    "Artificial intelligence that removes and applies makeup": "Искусственный интеллект который снимает и наносит макияж",
                    "Demakeup": "Снять макияж",
                    "Makeup": "Нанести макияж",
                    "Upload your photo": "Загрузите ваше фото",
                    "Original": "Оригинал",
                    "Rules": "Для того, чтобы результат вас не напугал, требования к фото):",
                    "Rule 1": "1 - На лице не должно быть экстремально пестрого макияжа/фотошопа;",
                    "Rule 2": "2 - Лицо или голова полностью в кадре;",
                    "Rule 3": "3 - Лицо расположено вертикально;",
                    "Rule 4": "4 - Лицо на фото не засвечено;",
                    "Result": "Результат",
                    "Download photo": "Скачать фото",
                    "Sorry, the robot is learning": "Извините, робот учится."
                }
            },
            en: {
                translation: {
                    "Artificial intelligence that removes and applies makeup": "Artificial intelligence that removes and applies makeup",
                    "Demakeup": "Demake up",
                    "Makeup": "Make up",
                    "Upload your photo": "Upload your photo",
                    "Original": "Original",
                    "Rules": "So that the result does not scare you, photo requirements):",
                    "Rule 1": "1 - There should be no extremely colorful makeup / photoshop on the face;",
                    "Rule 2": "2 - The face or head is completely in the frame;",
                    "Rule 3": "3 - The face is vertical;",
                    "Rule 4": "4 - The face in the photo is not blown out;",
                    "Result": "Result",
                    "Download photo": "Download photo",
                    "Sorry, the robot is learning": "Sorry, the robot is learning."
                }
            }
        },
        lng: "en",
        fallbackLng: "en",

        interpolation: {
            escapeValue: false
        }
    });
