import atomize from "@quarkly/atomize"; // Import the keyframes

import styled, { keyframes } from "styled-components"; // Create the keyframes

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
`; // Here we create a component that will rotate everything we pass in over two seconds

const Rotate = styled.div`
  display: block;
  width: 100px;
  height: 100px;
  animation: ${rotate} 1.5s linear infinite;
  padding: 2rem 1rem;
  border-radius: 50%;
  border: 2px solid --red;
`;
export default atomize(Rotate)({
	name: "Sdaasd",
	effects: {
		hover: ":hover"
	},
	normalize: true,
	mixins: true,
	description: {
		// past here description for your component
		en: "Sdaasd — my awesome component"
	},
	propInfo: {
		// past here props description for your component
		yourCustomProps: {
			control: "input"
		}
	}
});