export function reachGoal(goal, callback) {
  if (process.env.NODE_ENV === 'production') {
    if (window.yaCounter65913448) {
      window.yaCounter65913448.reachGoal(goal, callback);
    } else if (callback) {
      callback();
    }
  } else {
    console.log(`Reach goal: ${goal}`); //eslint-disable-line
    if (callback) {
      callback();
    }
  }
}

export const GOALS = {
  // Снятие макияжа
  PHOTO_ATTACHED: 'photo_attached', // Пользователь загрузил фото
  FILEUPLOADER_OPENED: 'fileuploader_opened', // Пользователь кликнул по кнопке "Загрузите ваше фото"
  PROCESSING_SUCCEEDED: 'processing_succeeded', // Фото успешно обработано
  PROCESSING_FAILED: 'processing_failed', // Не удалось обработать фото
  PHOTO_DOWNLOADED: 'photo_downloaded', // Пользователь скачал фото
  PHOTO_DOWNLOADED2: 'photo_downloaded2', // Пользователь скачал фото
  PHOTO_DOWNLOADED3: 'photo_downloaded3', // Пользователь скачал фото

  // Нанесение макияжа
  N_PHOTO_ATTACHED: 'n_photo_attached', // Пользователь загрузил фото
  N_FILEUPLOADER_OPENED: 'n_fileuploader_opened', // Пользователь кликнул по кнопке "Загрузите ваше фото"
  N_PROCESSING_SUCCEEDED: 'n_processing_succeeded', // Фото успешно обработано
  N_PROCESSING_FAILED: 'n_processing_failed', // Не удалось обработать фото
  N_PHOTO_DOWNLOADED: 'n_photo_downloaded', // Пользователь скачал фото
  N_PHOTO_DOWNLOADED2: 'n_photo_downloaded2', // Пользователь скачал фото
  N_PHOTO_DOWNLOADED3: 'n_photo_downloaded3', // Пользователь скачал фото
};
