import { Override, Section, Stack, StackItem } from "@quarkly/components";
import { Box, Button, Icon, Span, Text, Theme } from "@quarkly/widgets";
import loadImage from "blueimp-load-image";
import * as Components from "components";
import * as FileSaver from "file-saver";
import React from "react";
import { FaSadTear } from "react-icons/fa";
import { MdArrowForward } from "react-icons/md";
import theme from "theme";
import { Trans, getI18n } from 'react-i18next';

import { sendImage } from "../api";
import { GOALS, reachGoal } from "../api/metrika";

export default class RuVersion extends React.Component {
  constructor(props) {
    super(props);

    // Slice for remove slash
    const { pathname } = this.props.location;
    const pathnameSplitted = pathname.split('/');
    // Example of pathnameSplitted ["", "en", "demakeup"]

    this.lang = pathnameSplitted[1];
    this.operationType = pathnameSplitted[2];
    this.backendMessage = null;

    getI18n().changeLanguage(this.lang);

    this.styles = {
      input: {
        left: "-9999px",
        position: "absolute",
      },
    };

    let leftBlockStartImage = "--color-lightD1 url(https://artemzhigalin.ru/wp-content/uploads/2020/07/2020-07-13-10-59-34.jpg) center left/200% no-repeat";
    let rightBlockStartImage = "--color-lightD1 url(https://artemzhigalin.ru/wp-content/uploads/2020/07/2020-07-13-10-59-34.jpg) center right/200% no-repeat";


    if (this.operationType === 'makeup') {
      rightBlockStartImage = [leftBlockStartImage, leftBlockStartImage = rightBlockStartImage][0]; // swap
    }

    this.state = {
      originalImage: null,
      leftBlockStartImage: leftBlockStartImage,
      rightBlockStartImage: rightBlockStartImage,
      resultImage: null,
      resultImageArray: [],
      resultImageArrayForButtons: [],
      isLoading: false,
      requestRejected: false,
      preview: true,
    };

    this.fileInput = null;
    this.result = null;

    this.setFileInputRef = (element) => (this.fileInput = element);
    this.onUpload = this.onUpload.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.downloadResult = this.downloadResult.bind(this);
    this.downloadResult2 = this.downloadResult2.bind(this);
    this.downloadResult3 = this.downloadResult3.bind(this);
  }

  onUpload() {
    this.fileInput.click();
    if (this.operationType === 'demakeup') {
      reachGoal(GOALS.FILEUPLOADER_OPENED);
    }

    if (this.operationType === 'makeup') {
      reachGoal(GOALS.N_FILEUPLOADER_OPENED);
    }
  }

  onFileChange(event) {
    const image = event.target.files[0];

    new Promise((resolve) => {
      loadImage(
        image,
        (canvas) => canvas.toBlob((blob) => resolve(blob), "image/jpeg"),
        {
          orientation: true,
          canvas: true,
        }
      );
    })
      .then((rotatedImage) => {
        const originalImageUrl = URL.createObjectURL(rotatedImage);

        if (this.operationType === 'demakeup') {
          reachGoal(GOALS.PHOTO_ATTACHED);
        }

        if (this.operationType === 'makeup') {
          reachGoal(GOALS.N_PHOTO_ATTACHED);
        }

        this.setState({
          originalImage: `url(${originalImageUrl})`,
          requestRejected: false,
          resultImage: null,
          resultImageArray: [],
          resultImageArrayForButtons: [],
          isLoading: true,
          preview: false,
        });

        this.sendImage(rotatedImage);
      })
      .catch(() => {
        this.setState({
          requestRejected: true,
          isLoading: false,
        });

        if (this.operationType === 'demakeup') {
          reachGoal(GOALS.PROCESSING_FAILED);
        }

        if (this.operationType === 'makeup') {
          reachGoal(GOALS.N_PROCESSING_FAILED);
        }
      });
  }

  downloadResult() {
    if (this.state.resultImageArrayForButtons.length <=0) {
      return;
    }

    FileSaver.saveAs(this.state.resultImageArrayForButtons[0], "makeup-removal-result.jpg");

    if (this.operationType === 'demakeup') {
      reachGoal(GOALS.PHOTO_DOWNLOADED);
    }

    if (this.operationType === 'makeup') {
      reachGoal(GOALS.N_PHOTO_DOWNLOADED);
    }
  }

  downloadResult2() {
    if (this.state.resultImageArrayForButtons.length < 1) {
      return;
    }

    FileSaver.saveAs(this.state.resultImageArrayForButtons[1], "makeup-removal-result.jpg");

    if (this.operationType === 'demakeup') {
      reachGoal(GOALS.PHOTO_DOWNLOADED2);
    }

    if (this.operationType === 'makeup') {
      reachGoal(GOALS.N_PHOTO_DOWNLOADED2);
    }
  }

  downloadResult3() {
    if (this.state.resultImageArrayForButtons.length < 2) {
      return;
    }

    FileSaver.saveAs(this.state.resultImageArrayForButtons[2], "makeup-removal-result.jpg");

    if (this.operationType === 'demakeup') {
      reachGoal(GOALS.PHOTO_DOWNLOADED3);
    }

    if (this.operationType === 'makeup') {
      reachGoal(GOALS.N_PHOTO_DOWNLOADED3);
    }
  }

  sendImage(image) {
    this.backendMessage = null;

    sendImage(image, this.operationType)
      .then((result) => {

        this.setState({
          isLoading: false,
        });

        if (result.message) {
          if (this.lang === 'ru') {
            if (result.message === 'no_face_found') {
              this.backendMessage = 'Кажется на фото нет изображения лица, загрузи пожалуйста другое';
            }
            if (result.message === 'bad_resolution') {
              this.backendMessage = 'Я вижу, что фото плохого разрешения, загрузи пожалуйста более качественное фото';
            }
            if (result.message === 'bad_rotation_of_face') {
              this.backendMessage = 'На фото лицо скорее всего немного повернуто в сторону, я пока плохо умею обрабатывать такие фото, но если интересно и не страшно, взгляни на результат.';
            }
            if (result.message === 'invalid_sex') {
              this.backendMessage = 'Кажется на фото парень, я пока учусь это понимать, загрузи пожалуйста другое фото';
            }
            if (result.message === 'ok') {
              this.backendMessage = 'Все хорошо!';
            }
          } else {
            if (result.message === 'no_face_found') {
              this.backendMessage = ' It seems, there is no face on this picture. Download another, please';
            }
            if (result.message === 'bad_resolution') {
              this.backendMessage = 'This photo is of poor resolution, please, upload more qulitative photo';
            }
            if (result.message === 'bad_rotation_of_face') {
              this.backendMessage = 'The face on this photo is turned to the side, for now, i can\'t handle pictures like that, but if you\'re not scared and interested enough, take a look at the result.';
            }
            if (result.message === 'invalid_sex') {
              this.backendMessage = 'It looks like a guy in the photo, I\'m still learning to understand this, please upload another photo';
            }
            if (result.message === 'ok') {
              this.backendMessage = 'Ok!';
            }

            this.setState({
              backendMessage: this.backendMessage,
            });
          }
        }

        if (!result.urls || result.urls.length < 1) {
          this.setState({
            requestRejected: true,
          });

          if (this.operationType === 'demakeup') {
            reachGoal(GOALS.PROCESSING_FAILED);
          }

          if (this.operationType === 'makeup') {
            reachGoal(GOALS.N_PROCESSING_FAILED);
          }
        }

        const resultImageArray = [];
        const resultImageArrayForButtons = [];

        result.urls.map((image) => {
          resultImageArray.push(`url(${image})`);
          resultImageArrayForButtons.push(image);
          this.setState({
            resultImageArray: resultImageArray,
            resultImageArrayForButtons: resultImageArrayForButtons,
            isLoading: false,
          });
        });

        if (this.operationType === 'demakeup') {
          reachGoal(GOALS.PROCESSING_SUCCEEDED);
        }

        if (this.operationType === 'makeup') {
          reachGoal(GOALS.N_PROCESSING_SUCCEEDED);
        }
      })
      .catch((result) => {
        this.setState({
          requestRejected: true,
          isLoading: false,
        });

        if (result.message) {
          if (this.lang === 'ru') {
            if (result.message === 'no_face_found') {
              this.backendMessage = 'Кажется на фото нет изображения лица, загрузи пожалуйста другое';
            }
            if (result.message === 'bad_resolution') {
              this.backendMessage = 'Я вижу, что фото плохого разрешения, загрузи пожалуйста более качественное фото';
            }
            if (result.message === 'bad_rotation_of_face') {
              this.backendMessage = 'На фото лицо скорее всего немного повернуто в сторону, я пока плохо умею обрабатывать такие фото, но если интересно и не страшно, взгляни на результат.';
            }
            if (result.message === 'invalid_sex') {
              this.backendMessage = 'Кажется на фото парень, я пока учусь это понимать, загрузи пожалуйста другое фото';
            }
            if (result.message === 'ok') {
              this.backendMessage = 'Все хорошо!';
            }
          } else {
            if (result.message === 'no_face_found') {
              this.backendMessage = ' It seems, there is no face on this picture. Download another, please';
            }
            if (result.message === 'bad_resolution') {
              this.backendMessage = 'This photo is of poor resolution, please, upload more qulitative photo';
            }
            if (result.message === 'bad_rotation_of_face') {
              this.backendMessage = 'The face on this photo is turned to the side, for now, i can\'t handle pictures like that, but if you\'re not scared and interested enough, take a look at the result.';
            }
            if (result.message === 'invalid_sex') {
              this.backendMessage = 'It looks like a guy in the photo, I\'m still learning to understand this, please upload another photo';
            }
            if (result.message === 'ok') {
              this.backendMessage = 'Ok!';
            }
          }

          this.setState({
            backendMessage: this.backendMessage,
          });
        }

        if (this.operationType === 'demakeup') {
          reachGoal(GOALS.PROCESSING_FAILED);
        }

        if (this.operationType === 'makeup') {
          reachGoal(GOALS.N_PROCESSING_FAILED);
        }
      });
  }

  render() {
    return (
      <Theme theme={theme}>
        <Section
          padding="30px 0px 40px 0px"
          sm-padding="20px 0"
          min-height="100vh"
          sm-min-height="auto"
          background="--color-lightD2"
          display="flex"
          flex-direction="column"
          inner-max-width="800px"
        >
          <div style={({width: '100%'})}>
            <a href={'/en/' + this.operationType}>
              English
            </a>
            <span style={({ marginLeft: '5px', marginRight: '5px' })}>|</span>
            <a href={'/ru/' + this.operationType}>
              Русский
            </a>
            <a href={'https://visionsystems.tech'}
               style={({ display: 'inline-block', float: 'right', textDecoration: 'none', fontWeight: 'bold' })}
               target={'blank'}>
              <span style={({ color: '#4ca875' })}>Vision</span>
              <span style={({ color: '#666666' })}>Systems</span>
              <span style={({ color: '#424242' })}>.tech</span>
            </a>
          </div>

          <Text
            as="h1"
            margin="-10px 0px 22px"
            font="normal 900 52px/1 --fontFamily-googleMontserrat"
            md-font="--headline1"
            color="--darkL2"
            max-width="850px"
            sm-font="--headline1Sm"
            word-break="break-word"
            sm-text-align="center"
            sm-margin="8px 0px 22px 0px"
          >
            Makeup
            <Span
              font="normal 900 52px/86.4px --fontFamily-googleMontserrat"
              margin="0px 0px 0px 10px"
              color="--red"
              text-shadow="2px 2px 1px #9a2b03"
              sm-font="italic 900 36px/1.2 --fontFamily-googlePermanentMarker"
              sm-display="inline-block"
              md-display="inline-block"
              sm-margin="0px 18px 0px 0px"
            >
              Transfer
            </Span>
          </Text>

          <Text
            font="--base"
            text-transform="uppercase"
            letter-spacing="2px"
            max-width="850px"
            color="--darkL2"
            margin="6px"
            sm-font="--baseSm"
            sm-text-align="center"
          >
            <Trans>Artificial intelligence that removes and applies makeup</Trans>
          </Text>

          <Stack>
            <StackItem width="50%" display="flex" md-width="100%">
              <Override
                slot="StackItemContent"
                flex-direction="column"
                align-items="center"
                background="--color-light"
                padding="18px 18px 18px 18px"
                position="relative"
                border-radius="8px"
                box-shadow="--xl"
              />

              <Text
                font="--lead"
                margin="0px 0px 18px 0px"
                display="inline-block"
                text-align="center"
                text-transform="uppercase"
                letter-spacing="2px"
                color="--darkL2"
                sm-font="--leadSm"
                sm-margin="0px 0px 8px 0px"
              >
                <Trans>Original</Trans>
              </Text>

              <Box
                width="100%"
                height="340px"
                margin="0px 0px 12px 0px"
                border-radius="6px"
                sm-height="240px"
                background={this.state.leftBlockStartImage}
                min-height="none"
                padding="0px 0px 0px 0px"
                display={this.state.preview ? "block" : "none"}
              />

              <Box
                width="100%"
                height="340px"
                margin="0px 0px 12px 0px"
                border-radius="6px"
                sm-height="240px"
                background="--color-lightD1 center no-repeat"
                background-size="contain"
                background-image={this.state.originalImage}
                min-height="none"
                padding="0px 0px 0px 0px"
                display={this.state.preview ? "none" : "block"}
              />

              <input
                type="file"
                accept="jpg, jpeg, png"
                style={this.styles.input}
                ref={this.setFileInputRef}
                onChange={this.onFileChange}
              />

              <Button
                padding="7px 18px 8px 18px"
                font="--base"
                border-radius="54px"
                background="linear-gradient(180deg,#1B6FE3 0%,--color-primary 100%) 0 0 no-repeat"
                hover-background="linear-gradient(180deg,#2a97ef 0%,#1575ec 100%) 0 0 no-repeat"
                transition="background --transitionDuration-normal --transitionTimingFunction-easeOut 0s"
                onClick={this.onUpload}
              >
                <Trans>Upload your photo</Trans>
              </Button>

              <Text font="--base" color="--red" lg-text-align="center">
                <Trans>Rules</Trans>
                <br />
                <Trans>Rule 1</Trans>
                <br />
                <Trans>Rule 2</Trans>
                <br />
                <Trans>Rule 3</Trans>
                <br />
                <Trans>Rule 4</Trans>
              </Text>

              <Icon
                category="md"
                icon={MdArrowForward}
                size="74px"
                color="--light"
                background="--color-darkL2"
                border-radius="50%"
                position="absolute"
                top={0}
                bottom={0}
                right="-54px"
                box-shadow="--xl"
                border-width="6px"
                border-style="solid"
                border-color="--color-light"
                width="65px"
                height="65px"
                mix-blend-mode="multiply"
                md-top="auto"
                md-left="auto"
                md-right="auto"
                md-bottom="-53px"
                md-transform="--rotate90"
                z-index="3"
                md-width="64px"
                md-height="64px"
                md-font="48px sans-serif"
                font="54px sans-serif"
                margin="auto 0px auto 0px"
              />
            </StackItem>

            <StackItem width="50%" display="flex" md-width="100%">
              <Override
                slot="StackItemContent"
                flex-direction="column"
                align-items="center"
                background="--color-light"
                padding="18px 18px 18px 18px"
                border-radius="8px"
                box-shadow="--xl"
                md-padding="18px 18px 54px 18px"
              />

              <Text
                font="--lead"
                margin="0px 0px 18px 0px"
                display="inline-block"
                text-align="center"
                text-transform="uppercase"
                letter-spacing="2px"
                color="--darkL2"
                sm-font="--leadSm"
              >
                <Trans>Result</Trans>
              </Text>

              <Box
                position="relative"
                display="flex"
                flex-direction="column"
                align-items="center"
                width="100%"
              >
                <Components.LoadingSpinner
                  position="absolute"
                  width="100px"
                  height="100px"
                  border-width="4px"
                  border-style="solid"
                  border-color="--color-red"
                  margin="auto auto auto auto"
                  bottom="0px"
                  top="0px"
                  left="0px"
                  right="0px"
                  mix-blend-mode="normal"
                  display={this.state.isLoading ? "block" : "none"}
                />

                <Box
                  width="100%"
                  height="340px"
                  margin="0px 0px 12px 0px"
                  border-radius="6px"
                  sm-height="240px"
                  background={this.state.rightBlockStartImage}
                  display={!this.state.requestRejected && this.state.resultImageArray.length < 1 ? "block" : "none"}
                  min-height="none"
                  padding="0px 0px 0px 0px"
                />

                <div style={({ display: !this.state.requestRejected ? "block" : "none", marginBottom: '20px'})}>
                  { this.backendMessage }
                </div>

                {/* Фотка 1 */}
                <Box
                  width="100%"
                  height="340px"
                  margin="0px 0px 12px 0px"
                  border-radius="6px"
                  sm-height="240px"
                  background="--color-lightD1 center center no-repeat"
                  background-size="contain"
                  background-image={this.state.resultImageArray[0]}
                  display={!this.state.requestRejected && this.state.resultImageArray.length > 0 ? "block" : "none"}
                  min-height="none"
                  padding="0px 0px 0px 0px"
                />
                {
                  !this.state.requestRejected && this.state.resultImageArrayForButtons.length > 0 ?
                    (
                      <Button
                        padding="7px 18px 8px 18px"
                        style={({ marginBottom: '35px' })}
                        font="--base"
                        border-radius="54px"
                        background="--color-grey"
                        transition="background --transitionDuration-normal --transitionTimingFunction-easeOut 0s"
                        hover-background="#8d9eb8"
                        onClick={this.downloadResult}
                      >
                        <Trans>Download photo</Trans>
                      </Button>
                    )
                    : null
                }

                {/* Фотка 2 */}
                <Box
                  width="100%"
                  height="340px"
                  margin="0px 0px 12px 0px"
                  border-radius="6px"
                  sm-height="240px"
                  background="--color-lightD1 center center no-repeat"
                  background-size="contain"
                  background-image={this.state.resultImageArray[1]}
                  display={!this.state.requestRejected && this.state.resultImageArray.length > 1 ? "block" : "none"}
                  min-height="none"
                  padding="0px 0px 0px 0px"
                />
                {
                  !this.state.requestRejected && this.state.resultImageArrayForButtons.length > 1 ?
                    (
                      <Button
                        padding="7px 18px 8px 18px"
                        style={({ marginBottom: '35px' })}
                        font="--base"
                        border-radius="54px"
                        background="--color-grey"
                        transition="background --transitionDuration-normal --transitionTimingFunction-easeOut 0s"
                        hover-background="#8d9eb8"
                        onClick={this.downloadResult2}
                      >
                        <Trans>Download photo</Trans>
                      </Button>
                    )
                    : null
                }

                {/* Фотка 3 */}
                <Box
                  width="100%"
                  height="340px"
                  margin="0px 0px 12px 0px"
                  border-radius="6px"
                  sm-height="240px"
                  background="--color-lightD1 center center no-repeat"
                  background-size="contain"
                  background-image={this.state.resultImageArray[2]}
                  display={!this.state.requestRejected && this.state.resultImageArray.length > 2 ? "block" : "none"}
                  min-height="none"
                  padding="0px 0px 0px 0px"
                />
                {
                  !this.state.requestRejected && this.state.resultImageArrayForButtons.length > 2 ?
                    (
                      <Button
                        padding="7px 18px 8px 18px"
                        style={({ marginBottom: '35px' })}
                        font="--base"
                        border-radius="54px"
                        background="--color-grey"
                        transition="background --transitionDuration-normal --transitionTimingFunction-easeOut 0s"
                        hover-background="#8d9eb8"
                        onClick={this.downloadResult3}
                      >
                        <Trans>Download photo</Trans>
                      </Button>
                    )
                    : null
                }

                <Box
                  width="100%"
                  height="auto"
                  margin="0px 0px 12px 0px"
                  border-radius="6px"
                  sm-height="240px"
                  background="--color-lightD1 url() center/contain no-repeat"
                  display={this.state.requestRejected ? "flex" : "none"}
                  align-items="center"
                  flex-direction="column"
                  justify-content="center"
                  min-height="none"
                  padding="74px 0px 74px 0px"
                >
                  <Icon
                    category="fa"
                    icon={FaSadTear}
                    font="72px sans-serif"
                    color="--red"
                    margin="0px 0px 20px 0px"
                    size="72px"
                  />

                  <Text
                    font="--base"
                    margin="0px auto 18px auto"
                    display="inline-block"
                    text-align="center"
                    color="--red"
                    sm-font="--baseSm"
                    padding="0px 28px 0px 28px"
                    letter-spacing="2px"
                    text-transform="uppercase"
                  >
                    <span>{ this.backendMessage }</span>
                  </Text>
                </Box>
              </Box>

            </StackItem>

            <StackItem width="100%"
                       md-width="100%"
                       display={this.lang === 'ru' ? "flex" : "none"}>
              <Override
                slot="StackItemContent"
                flex-direction="column"
                align-items="center"
                background="--color-light"
                padding="18px 18px 18px 18px"
                border-radius="8px"
                box-shadow="--xl"
                md-padding="18px 18px 54px 18px"
              />

              <Box
                position="relative"
                display="flex"
                flex-direction="column"
                align-items="center"
                width="100%"
              >
                <iframe
                  width="120%"
                  height="1260"
                  style={{
                    border: "none",
                    borderRadius: "6px",
                  }}
                  src="https://docs.google.com/forms/d/e/1FAIpQLSebqq8Sd9SayrjcA8U7FTkmoq8oqsCXE5hOwBxx-2P-3Wer4Q/viewform?embedded=true"
                  title="Feedback form"
                ></iframe>
              </Box>
            </StackItem>
          </Stack>
        </Section>
      </Theme>
    );
  }
}
