import axios from "axios";

//const operationTypeAllowedValues = ['makeup', 'demakeup'];
const operationTypeAllowedValues = ['makeup'];
const operationTypeDefault = operationTypeAllowedValues[0];

if (process.env.NODE_ENV === "development") {
  axios.defaults.baseURL = "http://135.181.26.12:5000/";
} else {
  axios.defaults.baseURL = window.location.origin;
  //  axios.defaults.baseURL = "http://135.181.26.12:5000/";
}

axios.defaults.withCredentials = true;

export function checkVersion() {
  return axios.get("version").then((response) => response.data);
}

export function sendImage(file, operationType) {
  // Check operationType
  // Allowed values see in operationTypeAllowedValues const
  if (!operationTypeAllowedValues.includes(operationType)) {
    operationType = operationTypeDefault;
  }

  const formData = new FormData();
  formData.append("image", file);

  return axios
    .post(`api/1/image_processing?operation=${operationType}`, formData)
    .then((response) => response.data);
}
